<template>
    <div class="mt-3" data-testid="cruise-search">
        <div class="row">
            <div class="-query-container col-12 col-lg-4">
                <label class="select--styled mb-0">
                    <select v-model="cruiseLines" name="cruiseLines" aria-label="Cruise Lines">
                        <option value="">Any Cruise Line</option>
                        <option v-for="(brand, brandIndex) in brands" :key="brandIndex" :value="brand.name" v-html="brand.name"></option>
                    </select>
                </label>
            </div>
            <div class="col-12 col-lg-3 mt-1 mt-lg-0">
                <label class="select--styled mb-0">
                    <select v-model="destination" name="destination" aria-label="destination">
                        <option value="">Any Destination</option>
                        <option value="Africa &amp; Arabian Peninsula">Africa &amp; Arabian Peninsula</option>
                        <option value="Alaska &amp; Pacific Northwest">Alaska &amp; Pacific Northwest</option>
                        <option value="Asia">Asia</option>
                        <option value="Canada &amp; New England">Canada &amp; New England</option>
                        <option value="Caribbean">Caribbean</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Mediterranean &amp; Southern Europe">Mediterranean &amp; Southern Europe</option>
                        <option value="Mexico &amp; Central America">Mexico &amp; Central America</option>
                        <option value="Northern Europe &amp; the British Isles">Northern Europe &amp; the British Isles</option>
                        <option value="South America &amp; Antarctica">South America &amp; Antarctica</option>
                        <option value="South Pacific">South Pacific</option>
                        <option value="Western &amp; Central Europe">Western &amp; Central Europe</option>
                    </select>
                </label>
            </div>
            <div class="col-12 col-lg-3 mt-1 mt-lg-0">
                <date-picker-component id="filter-dates" placeholder-text="Travel Dates"></date-picker-component>
            </div>
            <div class="col-2 mt-0 d-none d-lg-block">
                <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch">Search</button>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-lg-2">
                <button class="btn btn-xs btn-tertiary" @click="toggleMoreFilters"><i :class="(isMoreFiltersOpen) ? 'icon-angle-down-ut' : 'icon-angle-right-ut'"></i> More filters</button>
            </div>
            <div id="more-filters-panel" class="col-12 col-lg-10 text--small">
                <div class="row">
                    <div class="col-6 col-md-3 col-lg-2">
                        <span class="text-emphasis font-weight-bold">Duration</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in durationFacets" :key="facetIndex">
                                <label>
                                    <input :id="'lengths-' + facet" :key="'lengths-' + facet" v-model="selectedLengths" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <span class="text-emphasis font-weight-bold">Style</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in styleFacets" :key="facetIndex">
                                <label>
                                    <input :id="'cruiseTypes-' + facet" :key="'cruiseTypes-' + facet" v-model="selectedCruiseTypes" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <span class="text-emphasis font-weight-bold">Virtuoso Exclusives</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in exclusivesFacets" :key="facetIndex">
                                <label>
                                    <input :id="'exclusives-' + facet" :key="'exclusives-' + facet" v-model="selectedExclusives" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5">
                        <span class="text-emphasis font-weight-bold">Keyword</span>
                        <AutocompleteInput v-model="query" :show-icon="false" :show-mobile-button="false" :type="ProductType.CRUISES" placeholder-text="Keyword" @search="doSearch" @select="selectCategory" @clear="() => { query = '' }" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2 d-lg-none">
                <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch">Search</button>
            </div>
        </div>
        <div class="mt-2 text--small fw-bold d-none d-lg-block">
            <a :href="b2bCatalogLink" v-html="legacyLinkText"></a>
        </div>
    </div>
</template>


<script lang="ts">
    import { facetsByProduct } from "config/search-facet-data";
    import { ProductType } from "interfaces/enums";
    import { BoxLink } from "interfaces/link";
    import { ProductSearchConfig } from "interfaces/product";
    import { trackOmniboxSearch } from "modules/analytics";
    import { isNetworkUser, isSupplier } from "modules/user-info";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { defineComponent, PropType } from "vue";
    import AutocompleteInput from "vue-components/autocomplete-input.vue";
    import DatePickerComponent from "vue-components/date-picker.vue";

    export default defineComponent({
        components: {
            AutocompleteInput,
            DatePickerComponent
        },

        props: {
            brands: {
                type: Array as PropType<BoxLink[]>,
                default: undefined
            }
        },

        data() {
            return {
                ProductType,
                b2bCatalogLink: virtuosoSharedHeader.cobrandLink("/cruises"),
                cruiseLines: "",
                destination: "",
                durationFacets: facetsByProduct[ProductType.CRUISES].find((category: ProductSearchConfig) => category.categoryName === "lengths")?.facets,
                exclusivesFacets: facetsByProduct[ProductType.CRUISES].find((category: ProductSearchConfig) => category.categoryName === "virtuosoExclusives")?.facets,
                isMoreFiltersOpen: false,
                isNetworkUser: isNetworkUser(),
                legacyLinkText: `For ${(isSupplier()) ? "Partners" : "Advisors"}: Advanced Search`,
                omniboxCategorySearch: "",
                query: "",
                selectedCruiseTypes: [],
                selectedExclusives: [],
                selectedLengths: [],
                styleFacets: facetsByProduct[ProductType.CRUISES].find((category: ProductSearchConfig) => category.categoryName === "cruiseTypes")?.facets
            };
        },

        methods: {
            doSearch: function (): void {
                let queryHash = "#";

                // Omnibox Search
                if (this.omniboxCategorySearch !== "") {
                    queryHash += `${encodeURIComponent(this.omniboxCategorySearch.trim())}=${encodeURIComponent(this.query.trim())}&`;

                    // trackGAEvent in the selectCategory
                }

                // Text query
                if (this.query.trim() !== "" && this.omniboxCategorySearch === "") {
                    queryHash += `query=${encodeURIComponent(this.query.trim())}&sort=SearchRelevance&`;
                }

                // Destination
                if (this.destination !== "") {
                    queryHash += `destinations=${encodeURIComponent(this.destination)}&`;
                }

                // Cruise Line
                if (this.cruiseLines !== "") {
                    queryHash += `cruiseLines=${encodeURIComponent(this.cruiseLines)}&`;
                }

                // Travel dates (they'll either both be populated or neither)
                const startDate = $("#dp-start-date").val().toString();
                const endDate = $("#dp-end-date").val().toString();

                if (startDate !== "" && endDate !== "") {
                    queryHash += `startDate=${startDate}&endDate=${endDate}&`;
                }

                // Cruise length
                if (this.selectedLengths.length > 0) {
                    queryHash += `lengths=${this.selectedLengths.join("|")}&`;
                }

                // Cruise Type
                if (this.selectedCruiseTypes.length > 0) {
                    queryHash += `cruiseTypes=${this.selectedCruiseTypes.join("|")}&`;
                }

                // Virtuoso Exclusives
                if (this.selectedExclusives.length > 0) {
                    queryHash += `virtuosoExclusives=${this.selectedExclusives.join("|")}&`;
                }

                if (queryHash.slice(-1) === "&") {
                    queryHash = queryHash.slice(0, -1); // Trim trailing ampersand
                }

                window.location.href = virtuosoSharedHeader.cobrandLink(`/travel/luxury-cruises/search${queryHash}`);
            },

            selectCategory: function (acCategory: string, acValue: string): void {
                this.query = acValue;
                switch (acCategory) {
                    case "city":
                        this.omniboxCategorySearch = "citySearch";
                        break;
                    case "country":
                        this.omniboxCategorySearch = "countrySearch";
                        break;
                    case "cruise line name":
                        this.omniboxCategorySearch = "companySearch";
                        break;
                    case "all fields":
                        this.omniboxCategorySearch = "";
                        break;
                }

                trackOmniboxSearch(ProductType.CRUISES, acCategory, acValue);

                this.doSearch("Omnibox selection");
            },

            toggleMoreFilters: function (): void {
                if (this.isMoreFiltersOpen) {
                    $("#more-filters-panel").slideUp();
                } else {
                    $("#more-filters-panel").slideDown();
                }

                this.isMoreFiltersOpen = !this.isMoreFiltersOpen;
            }
        }
    });
</script>
