<template>
    <div :id="carouselId" class="extra-wide sponsored-product-carousel">
        <template v-for="(slide, slideIndex) in slides" :key="slideIndex">
            <div class="slide">
                <button v-if="slide.identifier && slide.heartType" title="Save to WanderList" class="wl-heartable -top-right d-lg-none" :data-wl-type="slide.heartType" :data-wl-id="slide.identifier" :data-wl-title="slide.title"></button>
                <button v-if="slide.identifier && slide.heartType" title="Save to WanderList" class="wl-heartable -save-this -top-right d-none d-lg-block" :data-wl-type="slide.heartType" :data-wl-id="slide.identifier" :data-wl-title="slide.title"></button>
                <a :href="slide.url">
                    <div class="title-block">
                        <div v-if="slide.slideType === 'hotel'" class="-bob">
                            <div class="-boblogo">
                                <img src="https://virtuoso-prod.dotcms.cloud/images/products/bob-logo-gray.svg" alt="Virtuoso Best of the Best logo" title="Virtuoso Best of the Best" class="d-lg-none">
                                <img src="https://virtuoso-prod.dotcms.cloud/images/products/bob-logo.svg" alt="Virtuoso Best of the Best logo" title="Virtuoso Best of the Best" class="d-none d-lg-block">
                            </div>
                            <div :class="['-name', (slide.title && slide.title.length > 30) ? '-long' : '']"><div v-html="slide.title"></div></div>
                        </div>
                        <div v-else-if="slide.slideType === 'article' || slide.slideType === 'destination'" class="-featured">
                            <div><span v-if="slide.slideType === 'article' && slide.sponsoredText" class="-sponsored-flag" v-html="slide.sponsoredText"></span></div>
                            <div class="d-none d-lg-block -type" v-html="getSlideTabText(slide.slideType, slide.title)"></div>
                            <div :class="['-headline', (slide.title && slide.title.length > 50) ? '-long' : '']" v-html="slide.title"></div>
                        </div>
                        <template v-else>
                            <div v-if="slide.logoUrl" class="-logo">
                                <img :src="slide.logoUrl" alt="Logo">
                            </div>
                        </template>
                    </div>
                    <img :src="slide.imageUrl" :class="getImageClass(slide.imageCropFocus)" :alt="slide.title" width="100%">
                    <div class="-nav context-dark">
                        <div v-for="(allSlides, allIndex) in slides" :key="allIndex" :class="['-title d-none d-lg-block text-decoration-none', (allIndex === slideIndex) ? '-current' : '']" @click="goToSlide($event, allIndex, slideIndex)">
                            <span v-html="getSlideTabText(allSlides.slideType, allSlides.title)"></span>
                        </div>
                        <div v-if="showMobileTitleTab(slide.slideType)" class="-title d-lg-none" v-html="getSlideTabText(slide.slideType, slide.title)"></div>
                    </div>
                </a>
            </div>
        </template>
    </div>
</template>


<script lang="ts">
    import { defineComponent, PropType, nextTick } from "vue";
    import { createCarousel, CarouselConfig } from "modules/carousel";
    import { CarouselSlide } from "interfaces/carousel";
    import { enableHearts } from "modules/wanderlist";
    import { getImageCropFocusClass } from "services/images";
    import { shuffleInPlace } from "services/utilities";
    import { CarouselSlideType } from "interfaces/types/app-types";

    interface SlideAdViewTracker {
        adSeen: boolean;
    }

    export default defineComponent({
        props: {
            randomize: {
                type: Boolean,
                default: false
            },
            slides: {
                type: Array as PropType<CarouselSlide[]>,
                default: undefined
            }
        },

        data() {
            return {
                carouselId: `carousel-${Date.now()}${Math.random().toString().substr(2)}`,
                SlideAdViewTracker: [] as SlideAdViewTracker[]
            };
        },

        computed: {
            carouselClassNames: function (): string {
                return `${this.carouselClass}`;
            }
        },

        beforeMount: function (): void {
            // Randomize slide order if necessary
            if (this.randomize) {
                shuffleInPlace(this.slides);
            }
        },

        mounted: function (): void {
            // Initialize local array to keep track of whether we've seen the shown ad slide or not, for tracking events to Broadstreet
            this.slides.forEach(() => {
                this.SlideAdViewTracker.push({
                    adSeen: false
                });
            });

            const carouselConfig: CarouselConfig = {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                dots: true,
                fade: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            dots: false
                        }
                    }
                ]
            };

            nextTick(() => { // Wait for the DOM to draw
                enableHearts($(".sponsored-product-carousel"));
                setTimeout(() => {
                    $(`#${this.carouselId}`).on("init", (() => {
                        this.logAdView(0); // Log the first slide view on load, as afterChange doesn't trigger until the "next" slide navigation
                     }) as EventListener);

                    createCarousel($(`#${this.carouselId}`), carouselConfig);

                    $(`#${this.carouselId}`).on("afterChange", ((event: Event, slick: Record<string, unknown>, currentSlide: number) => {
                        this.logAdView(currentSlide);
                    }) as EventListener);
                }, 100);
            });
        },

        methods: {
            getImageClass: function (cropFocus: string): string {
                const thisCropFocus = getImageCropFocusClass(cropFocus);
                return `img-fit--cover ${thisCropFocus}`;
            },

            getSlideTabText: function (slideType: CarouselSlideType, slideTitle: string): string {
                return (slideType === "article") ? "Featured Article" : (slideType === "destination") ? "Featured Destination" : slideTitle;
            },

            goToSlide: function (event: Event, navIndex: number, currentIndex: number): void {
                // If they clicked on "this" slide, let the click pass through to the <a>...
                // Otherwise, navigate to the appropriate slide
                if (navIndex !== currentIndex) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(`#${this.carouselId}`).slick("slickGoTo", navIndex);
                }
            },

            logAdView: function (slideIndex: number): void {
                if (this.slides[slideIndex].broadstreetAdId && !this.SlideAdViewTracker[slideIndex].adSeen) {
                    const campaignSuffix = (this.slides[slideIndex].broadstreetCampaignId) ? `/c${this.slides[slideIndex].broadstreetCampaignId}` : "";
                    const bsaImg = document.createElement("img");
                    bsaImg.src = `https://ad.broadstreetads.com/display/${this.slides[slideIndex].broadstreetAdId}${campaignSuffix}?ts=${Date.now()}`;
                    bsaImg.style.display = "none";
                    document.body.appendChild(bsaImg);

                    (this.SlideAdViewTracker[slideIndex] as SlideAdViewTracker).adSeen = true;
                }
            },

            showMobileTitleTab: function (slideType: CarouselSlideType): boolean {
                return (slideType === "article" || slideType === "destination") ? true : false;
            }
        }
    });
</script>
