import { trackEvent } from "modules/analytics";
import { capitalizeFirst } from "virtuoso-shared-web-ui";
import { createApp } from "vue";
import { mountApp } from "vue-app";
import ProductLandingPageComponent from "vue-components/pages/product-landing-page.vue";


$(() => {
    if (window.VIRTUOSO.productType) {
        const app = createApp(ProductLandingPageComponent);
        mountApp(app, "page-app");

        const trackItemName = capitalizeFirst(window.VIRTUOSO.productType).slice(0, -1);
        trackEvent("entry_view", { item_name: `${trackItemName}_Home`, item_category: trackItemName });
    } else {
        console.error("Product type not provided");
    }

});
